import React from 'react'
import '../eli-stuff/eli.css'
import EliPage from '../components/EliPage.js'

const Eli = () => {
    return (
        <EliPage/>
    )
}

export default Eli