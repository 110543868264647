import React from 'react'
import '../eli-stuff/eli.css'
import Layout from '../components/layout.js'
import Fade from 'react-reveal/Fade'

class EliPage extends React.Component {
    constructor() { 
        super()
        this.state = {
            assessmentsList: [
                {
                    frenchTitle: "Les alterantifs pour les rubriques d'évaluations",
                    frenchContent: "Ce PDF est une introduction pour comment utiliser un Guide pour la réussite des étudiants dans la salle de classe comme un alternatif des rubriques d'évaluations. Le document partage qu'est-ce que c'est un Guide pour la réussite des étudiants et comment utiliser.",
                    title: 'Alternative to Rubrics',
                    content: 'This PDF is an introduction to using a Guide to Student Success in the classroom as an alternative form of assessment to standard rubrics. The document shares what this form of assessment is and how to use it in the classroom.',
                    link: 'https://tc2.ca/uploads/PDFs/online_materials/Guides-to-student-success.pdf',
                    filters: ['english', 'elementary', 'secondary', 'assessment'],
                },
                {
                    frenchTitle: "Des conseils pour les enseignants",
                    frenchContent: "Ce site Web a un bibliothèque d'information thème précis pour les enseignants concernant sur différent éléments d'enseignement. Les informations varient des recommandations pour la première journée de l'école à comment intégrer les événements actuels.",
                    title: 'Tips for Teachers',
                    content: 'This webpage contains a library of theme-specific sets of tips (in link form) that supply information and tips for teachers regarding various components of teaching, ranging from pointers for the first day of class to how to incorporate current events into your pedagogy.',
                    link: 'https://www.nctm.org/tips/',
                    filters: ['english', 'elementary', 'secondary', 'assessment', 'special education']
                },
                {
                    frenchTitle: "La collection des réussites scolaire des étudiants",
                    frenchContent: "Cette présentation a une liste de 56 différentes façons pour évaluer les étudiants. Il y a une explication de chaque stratégie, parfois une image comme un exemple et quel type d'évaluation c'est. L'évaluation peut-être travaille des étudiants, auto-déclaration, technologie, évaluation par les pairs, observations par l'enseignant ou la rétroaction.",
                    title: 'Gathering Student Achievement',
                    content: 'This presentation includes a list of 56 different ways to assess students. There is an explaination of each strategy, sometimes an image as an example, as well as, what type of assessment it is. The assessment could be student work, self-reporting, technology, peer assessment, teacher observations, teacher feedback or self-assessment.',
                    link: 'https://docs.google.com/presentation/d/1nzhdnyMQmio5lNT75ITB45rHyLISHEEHZlHTWJRqLmQ/pub?slide=id.p',
                    filters: ['english', 'elementary', 'secondary', 'assessment']
                },
                {  
                    frenchTitle: "Évaluation par les yeux des étudiants",
                    frenchContent: "L'article suivant partage comment les étudiants sentissent les différents types d'évaluations. Cela inclut également les différents scénarios sur évaluation.",
                    title: 'Assessment Through the Eyes of the Student',
                    content: 'The following article shares how students expereince different types of assessment and includes different scenarios to help further the learning.',
                    link: "http://www.ascd.org/publications/educational-leadership/may07/vol64/num08/Assessment-Through-the-Student's-Eyes.aspx",
                    filters: ['english', 'elementary', 'secondary', 'assessment']
                },              
            ],
            classroomManagementList: [
                {  
                    frenchTitle: "Les règles alterantifs",
                    frenchContent: "Ce blog est rédigé par un enseignant expérimenté qui parle des règles de la classe et comment les introduire aux étudiants au début de l'année.",
                    title: 'Alternative Rules',
                    content: 'This blog entry is written by an experienced teacher who really dives into classroom rules and how to introduce them in a classroom.',
                    link: "https://adunsiger.com/2019/09/29/gum-hats-what-else-is-a-firm-no-always-necessary/",
                    filters: ['english', 'elementary', 'secondary', 'classroom management']
                },
                {   
                    frenchTitle: "Les straégies d'enseignement",
                    frenchContent: "Ce site Web contient des informations, des conseils, du contenu et des stratégies d'enseignement pour les enseignants de toutes les cours et de toutes les matières, en particulier la gestion et l'évaluation en classe. Pour certaines stratégies, des clips vidéo permettent aux enseignants de regarder une stratégie en action.",
                    title: 'Teaching Strategies',
                    content: 'This website contains information, tips, content, and teaching strategies for teachers of all grades and subjects, particularly classroom management and assessment. For some of the strategies, there are video clips for teachers to watch a strategy in action.',
                    link: "https://www.teachervision.com/teaching-strategies",
                    filters: ['english', 'elementary', 'secondary', 'assessment', 'technology', 'special education', 'classroom management']
                }, 
            ],
            specialEducationList: [
                {
                    frenchTitle: "Stratégies d’enseignement pour les étudiants ayant des besoins spéciaux",
                    frenchContent: "Ce site Web donne aux enseignants des stratégies d'enseignement pour enfance en difficulté. Ces stratégies sont organisées en fonction des besoins de l'étudiant, des anomalies et des conditions médicales / psychologiques diagnostiquées.",
                    title: 'Teaching Strategies for Students with Special Needs',
                    content: "This website provides teachers with teaching strategies for students with special needs. These strategies are organized by student's needs, exceptionalities, and diagnosed medical/psychological conditions.",
                    link: "https://www.teachspeced.ca/teaching-strategies-students-special-needs",
                    filters: ['english', 'french', 'elementary', 'secondary', 'classroom management', 'special education']
                },
                {
                    frenchTitle: "Les séminaires en ligne sur l'enfance en difficulté",
                    frenchContent: "Ces séminaires en ligne se concentre sur les supports de gestion de classe pour les étudiants avec THADA de la petite enfance.",
                    title: 'Special Education Webinar',
                    content: "This webinar focuses on classroom management supports for ADHD behaviours in early childhood education settings.",
                    link: 'https://chadd.org/webinars/classroom-management-supports-for-adhd-behaviors-in-early-childhood-education-settings/',
                    filters: ['english', 'elementary', 'classroom management', 'special education']
                },
                {   
                    frenchTitle: "Les exercices efficace pédagogique pour les étudiants du spectre autistique",
                    frenchContent: "Ce guide de ressources a été conçu pour aider les enseignants du primaire et du secondaire à mettre en œuvre des programmes éducatifs efficaces pour les étudiants atteints de spectre autistique. Troubles (TSA). Il comprend: des conseils pour les enseignants, des outils et des techniques, une analyse approfondie, la planification de programmes et des ressources supplémentaires. ",
                    title: 'Effective Educational Practices for Student with Autisum Spectrum Disorder',
                    content: "This resource guide was designed to support elementary and secondary teachers in the implementation of effective educational programs for students with Autism Spectrum Disorders (ASD). It includes: Tips for teachers, Tools and Techniques, Deep Insight, Program Planning, and Additional Resources.",
                    link: 'http://www.edu.gov.on.ca/eng/general/elemsec/speced/autismSpecDis.pdf',
                    filters: ['english', 'elementary', 'special education']
                }, 
                {   
                    frenchTitle: "L'apprentissage pour tous",
                    frenchContent: "Ce site Web a beaucoup de ressources que les éducateurs français peuvent utiliser pour l'apprentissage en ligne ou en classe.",
                    title: 'Learning for All',
                    content: "This website has a great array of resources for French educators to use during online learning or in the classroom.",
                    link: 'https://www.servicesauxeleves.ca/',
                    filters: ['french', 'elementary', 'special education']
                },
                {   
                    frenchTitle: "La participation à la vie sociale",
                    frenchContent: "Ce site Web aide les enseignants à rechercher des façons d'enseigner des compétences sociales aux étudiants.",
                    title: 'La participation à la vie sociale',
                    content: "This website helps teachers looking for ways to teach social skills to students.",
                    link: 'http://www.learnalberta.ca/content/inspf/html/index.html',
                    filters: ['french', 'elementary', 'secondary', 'special education']
                },  
            ],
            centuryCompetenciesList: [
                {
                    frenchTitle: "Les séminaires en ligne pédagogique",
                    frenchContent: "Cette ressource organise des séminaires en ligne mensuelle sur une variété de sujets informatifs et éducatifs",
                    title: 'Educational Webinars',
                    content: "This resource holds monthly webinars of a variety of informative and educational topics",
                    link: 'https://blog.edmentum.com/tags/professional-development',
                    filters: ['english', 'elementary', 'secondary', 'special education', 'assessment', 'critical literacy', 'early literacy', 'science', 'math', 'school leadership', 'classroom management', 'technology', '21st century competencies'
                    ]
                },
                {
                    frenchTitle: "Les documents pédagogique",
                    frenchContent: "Ce site Web a des liens vers des documents excellents sur l'intégration de la coopération et du contenu interdisciplinaire dans la salle de classe.",
                    title: 'Pedagogical Documents',
                    content: "This website includes links to great documents on integrating cooperation and interdisciplinary content into the classroom.",
                    link: 'https://new.learnalberta.ca/search?x=41AFE9AD&q=',
                    filters: ['french', 'elementary', 'secondary', 'technology', '21st century competencies'
                    ]
                },
                {
                    frenchTitle: "Les séminaires en ligne pour le développement professionnel",
                    frenchContent: "Ce site Web offre aux éducateurs de séminaires en ligne divers qui peuvent être consultés sur leur propre temps autour de divers sujets clés, y compris la santé mentale, l'alphabétisation et l'éducation spéciale.",
                    title: 'Professional Development webinar Series',
                    content: "This website provides educators with various webinars that can be viewed on their own time surrounding various key topics including mental health, literacy and special education.",
                    link: 'https://www.learnersedge.com/resources/webinars',
                    filters: ['english', 'elementary', 'secondary', 'critical literacy', 'special education', 'technology', '21st century competencies'
                    ]
                }, 
                {   
                    frenchTitle: "L'enseignement en ligne",
                    frenchContent: "Une petite collection de ressources sur le développement professionnel des enseignants (sites Web, podcasts, blogs) afin d'aider les enseignants à planifier et exécuter avec succès l'apprentissage à distance.",
                    title: 'Teaching Online',
                    content: "A small collection of teacher professional development resources (websites, podcasts, blogs) in order to help teachers with successfully planning and executing remote learning.",
                    link: 'https://tagpacker.com/user/teachthought.pd?t=Online_Teaching_Resources',
                    filters: ['english', 'elementary', 'secondary', 'technology', '21st century competencies'
                    ]
                }, 
                {   
                    frenchTitle: "L'apprentissage pour un futur durable: relier les points",
                    frenchContent: "Ce site Web fournit aux éducateurs des stratégies d'apprentissage pour l'éducation environnementale qui peuvent aider les étudiants à développer des compétences du 21e siècle. Cette ressource relis les problèmes environnementaux, économiques et sociaux à travers les matières tout en offrant aux étudiants la possibilité de créer des liens avec leurs propres communautés, expériences et connaissances.",
                    title: 'Learning for a Sustainable Future : Connecting the Dots',
                    content: "This website provides educators with learning strategies for environmental education that can help students develop 21st century skills. This resource links environemntal, economic and social issues across subjects while providing students with the opportunity to make connections to their own communities, experiences and knowledge.",
                    link: 'http://lsf-lst.ca/dots',
                    filters: ['english', 'french', 'science', 'elementary', 'secondary', 'technology', '21st century competencies'
                    ]
                },
                {   
                    frenchTitle: "Les podcast pour l'enseignants",
                    frenchContent: "Le podcast MindShift est une bonne ressource pour les enseignants qui veulent connaitre toutes les nouvelles innovations en éducation. Il explore l'avenir de l'apprentissage dans toutes ses dimensions. Il examine comment l'apprentissage est affecté par la technologie, les découvertes sur le cerveau, la pauvreté, les inégalités, la pleine conscience, l'action, l'apprentissage social et émotionnel, les évaluations, l'apprentissage basé sur le jeu et la musique, parmi de nombreux autres sujets.",
                    title: 'Teacher Podcast',
                    content: "MindShift podcast is a good resource for teachers wanting to keep up to date on new innovations in education. It explores the future of learning in all its dimensions. Its examines how learning is being affected by technology, discoveries about the brain, poverty, inequities, mindfulness, agency, social and emotional learning, assessments, game-based learning and music, among many other topics.",
                    link: 'https://www.kqed.org/mindshift',
                    filters: ['english', 'assessment', 'elementary', 'secondary', 'technology', '21st century competencies'
                    ]
                },
                {
                    frenchTitle: "Les ressources multimédia pour primaire et secondaire ",
                    frenchContent: "Ce site Web fournit aux enseignants de la maternelle à la 12e année des ressources pour chaque matière. Les enseignants peuvent rechercher sur ce site Web divers sujets dans un niveau scolaire et un sujet. Les ressources sont multidisciplinaires, car des vidéos et des textes sont disponibles.",
                    title: 'Multimedia Resources for K-12',
                    content: "This website provides K-12 educators with resources for each subject area. Educators can search this website for various topics within a grade level and subject. Resources are multidisciplinary as videos and texts are available.",
                    link: 'https://www.learner.org/',
                    filters: ['english', 'elementary', 'secondary', 'science', 'mathematics', 'critical literacy', 'technology', '21st century competencies'
                    ]
                },  
                {
                    frenchTitle: "L'engagement des étudiants dans des projets d'action durable",
                    frenchContent: "Cette ressource fournit aux enseignants un aperçu d'un cadre qui peut être utilisé pour aider les étudiants à s'engager dans des projets d'action durable.",
                    title: 'Engaging Students in Sustainable Action Projects',
                    content: "This resource provides teachers with an overview of a framework that can be used to help get students engaged in sustainable action projects.",
                    link: 'http://lsf-lst.ca/en/projects/teacher-resources/essap-guide',
                    filters: ['english', 'french', 'elementary', 'secondary', 'science', 'technology', '21st century competencies'
                    ]
                },
                {   
                    frenchTitle: "Les fondations socio-émotionnel",
                    frenchContent: "Le site Web s'est concentré sur la promotion du développement socio-affectif et de la préparation à l'école des jeunes enfants de la naissance à 5 ans. Il fournit aux éducateurs de la petite enfance des outils pour travailler à « l'établissement de relations » et les aide à améliorer l'enseignement des compétences socio-émotionnelles.",
                    title: 'Social Emotional Foundations',
                    content: "The website is focused on promoting the social-emotional development and school readiness of young children birth to age 5. It provides early-childhood educators with tools for working on “building relationships” and helps them to improve teaching social-emotional skills.",
                    link: 'http://csefel.vanderbilt.edu/index.html',
                    filters: ['english', 'elementary', 'classroom management', 'technology', '21st century competencies'
                    ]
                }, 
                {
                    frenchTitle: "L'enseignement à distance pour les enseignants et des familles",
                    frenchContent: "Le site Web soutient les éducateurs et les familles avec des ressources pour l'enseignement à distance en raison de COVID-19.",
                    title: 'Remote Learning for Educators and Families',
                    content: "The website supports educators and families facing the unprecedented challenge of remote learning due to COVID-19. It provides remote learning resources for educators and families.",
                    link: 'https://www.readworks.org/',
                    filters: ['english', 'elementary', 'secondary', 'technology', '21st century competencies'
                    ]
                },   
            ],
            earlyLiteracyList: [
                {
                    frenchTitle: "Les ressources pour le développement des étudiants multlingues",
                    frenchContent: "WIDA est un site Web qui fournit aux éducateurs et aux administrateurs des outils et des ressources de recherche de haute qualité, dédiés au développement du langage pour les apprenants multilingues de la maternelle à la 12e année. Les éducateurs peuvent rechercher des ressources d'apprentissage professionnel en fonction du sujet, du type et de la langue.",
                    title: 'Resources for Language Development in K-12 multilingual learners',
                    content: "WIDA is a website that provides educators and administrators with high-quality, research-based tools and resources, dedicated to the language development for K-12 multilingual learners. Educators can search for professional learning resources based on topic, type and language.",
                    link: 'https://wida.wisc.edu/resources/topics',
                    filters: ['english', 'elementary', 'secondary', 'critical literacy', 'technology', 'early literacy'
                    ]
                }, 
                {
                    frenchTitle: "Soutenant de petite enfance et leur développement",
                    frenchContent: "Ce site Web offre du développement professionnel pour les éducateurs des enfants et des jeunes, qui sont innovants et fondés sur la recherche. Il offre sept pistes de développement professionnel (nourrisson et enfant en bas âge, préscolaire, âge scolaire, spécialiste de la formation et du curriculum, gestion et garde d'enfants en famille).",
                    title: 'Supporting Early Learning and Development',
                    content: "This website provides an innovative, research-based professional Development for Child & Youth Educators, which offers seven professional development track (Infant & Toddler, Preschool, School-Age, Training & Curriculum Specialist, Management, and Family Child Care).",
                    link: 'https://www.virtuallabschool.org/learn',
                    filters: ['english', 'elementary', 'secondary', 'early literacy'
                    ]
                }, 
                {
                    frenchTitle: "Les séminaires en ligne gratuits pour les ensiegnants",
                    frenchContent: "Ce site Web offre aux professionnels de la petite enfance un accès à des séminaires en ligne gratuites portant sur des sujets pertinents dans le domaine de l'éducation de la petite enfance.",
                    title: 'Free Education Webinars',
                    content: "This website provides Early Education Professionals with access to free webinars focusing on a wide range of relevant topics to the field of early childhood education.",
                    link: 'https://www.gotostage.com/channel/2631925',
                    filters: ['english', 'elementary', 'secondary', 'special education', 'assessment', 'critical literacy', '21st century competencies', 'science', 'math', 'school leadership', 'classroom management', 'technology', 'early literacy'
                    ]
                }, 
                {
                    frenchTitle: "Soutenant des étudiants d'anglais langue seconde: Un guide pratique pour les éducateurs",
                    frenchContent: "Cette ressource aide les enseignants à comprendre les types de soutien que des étudiants d'anglais langue seconde ont besoin.",
                    title: 'Supporting English Language Learners: A practical guide for Ontario educators',
                    content: "This resource helps teachers understand the kinds of supports that English language learners require.",
                    link: 'https://www.octe.ca/application/files/3215/3175/8853/Elementary_ELL_guide.pdf',
                    filters: ['english', 'elementary', 'special education', 'early literacy'
                    ]
                },  
            ],
            schoolLeadershipList: [
                {
                    frenchTitle: "Les exercices éducatif leadership ",
                    frenchContent: "La direction d'école s'informe est un bulletin à l'intention des directions d'école et destiné à appuyer leur pratique du leadership pédagogique.",
                    title: 'Instructional Leadership Practices',
                    content: "Principals Want to Know is a series of tip sheets for principals to support their instructional leadership practice. Each PDf has additional resources and readings focusing on practical issues they are facing in schools.",
                    link: 'http://www.edu.gov.on.ca/eng/policyfunding/leadership/principalsWanttoKnow.html',
                    filters: ['english', 'french', 'elementary', 'secondary', 'school leadership'
                    ]
                }, 
                {
                    frenchTitle: "5 clés exercices pour comment le leadership peut guider un école",
                    frenchContent: "Ce PDF regardes le rôle des directeurs comme un leader. Il donne 5 clés pratiques sur la façon dont le bon leadership peut guider une école vers un meilleur enseignement et apprentissage.",
                    title: '5 Key Practicies to how good Leadership can Guide a School.',
                    content: "This PDF dives into the school principals role as the leader. It gives 5 key practicies to how good leadership can guide a school to better teaching and learning.",
                    link: 'https://www.wallacefoundation.org/knowledge-center/Documents/The-School-Principal-as-Leader-Guiding-Schools-to-Better-Teaching-and-Learning-2nd-Ed.pdf',
                    filters: ['english', 'elementary', 'secondary', 'school leadership'
                    ]
                }, 
                {
                    frenchTitle: "Comment conduire un école  inclusif",
                    frenchContent: "Cet article de revue partage trois choses clés que les directeurs devraient faire pour créer une communauté scolaire plus inclusive.",
                    title: 'Leading inclusive schools',
                    content: "This journal article shares three key things that principals should do to create a more inclusive school community. ",
                    link: 'https://theconversation.com/every-child-matters-what-principals-need-to-effectively-lead-inclusive-schools-114249',
                    filters: ['english', 'elementary', 'secondary', 'special education', 'school leadership'
                    ]
                }, 
                {
                    frenchTitle: "Explorer les pratiques de leadership",
                    frenchContent: "Le PDF suivant contient 8 études de cas pour aider les directeurs d'école à réfléchir sur des exemples concrets lorsqu'ils améliorent leurs capacités de leadership.",
                    title: 'Exploring leadership practices',
                    content: "The following PDF has 8 case studies to help principals think about real life examples when improving their leadership abilities. ",
                    link: 'https://www.oct.ca/-/media/PDF/Exploring%20Leadership%20Practices%20through%20Case%20Inquiry/ExplLdrshpPrctcsCseInqry_en__web.pdf',
                    filters: ['english','french', 'elementary', 'secondary', 'school leadership'
                    ]
                }, 
                {
                    frenchTitle: "Les directeurs comme leaders pédagogiques",
                    frenchContent: "Cette ressource explore l'importance du leadership pédagogique, du développement de leaders efficaces et du soutien du leadership pour les directeurs aspirants à des directeurs expérimentés.",
                    title: 'Principals as Instructional Leaders',
                    content: "This resource explores the importance of instructional leadership,  developing effective leaders, and leadership support for aspiring to experienced princicals.",
                    link: 'https://cassalberta.ca/wp-content/uploads/2018/11/Principal-Leadership-Literature-Review-Final-Version.pdf',
                    filters: ['english', 'elementary', 'secondary', 'school leadership'
                    ]
                }, 
            ],
            criticalLiteracyList: [
                {   
                    frenchTitle: "Ressources pédagogiques en ligne",
                    frenchContent: "Cliquer sur 'Guides d'enseignement efficace' pour voix les documents sur la littératie seulement. Ou cliquer sur 'Fiches d'activités provenant des modules' pour plus sur la littératie critique.",
                    title: 'Online pedagogy resources',
                    content: "Click on 'Guides d'enseignement efficace' to see documents related to only literacy, or 'Fiches d'activités provenant des modules' for all things critical literacy. ",
                    link: 'http://atelier.on.ca/edu/core.cfm?p=documentView&navID=documentView&c=0&type=2&L=2',
                    filters: ['french', 'elementary', 'secondary', 'critical literacy'
                    ]
                }, 
                {
                    frenchTitle: "Guide d’enseignement efficace en matière de littératie",
                    frenchContent: "Ce document relies la pratique et la recherche pour les éltudiants de la 4e à la 6e année. Le PDF concerne la lecture et l'écriture.",
                    title: 'Guide to effective literacy instruction',
                    content: "This document connects practice and research for students grade 4 to 6. The PDF is all about reading and writing. ",
                    link: 'http://atelier.on.ca/edu/resources/guides/Fascicule_1-2008.pdf',
                    filters: ['french', 'elementary', 'critical literacy'
                    ]
                }, 
                {
                    frenchTitle: "Guide d’enseignement efficace de la communication orale",
                    frenchContent: "Le PDF suivant contient tous pour créer une salle de classe de communication orale qui est efficace. Il comprend des conseils de planification, de gestion de classe et d'évaluation.",
                    title: 'Guide to effective oral communication instruction',
                    content: "The following PDF contains everything to create an effecitve oral communication classroom. It includes planning, classroom management, and evaluation tips. ",
                    link: 'http://atelier.on.ca/edu/resources/guides/GEE_Communication_orale_M_3.pdf',
                    filters: ['french', 'elementary', 'critical literacy', 'early literacy', 'assessment'
                    ]
                }, 
                {
                    frenchTitle: "Connectant la pratique et la recherche: Une guide pour la littératie critique",
                    frenchContent: "Cela explore l'importance de littératie critique dans l'éducation et fournit des stratégies d'enseignement efficace pour les étudiants du primaire au secondaire.",
                    title: 'Connecting Practice and Research: Critical Literacy Guide',
                    content: "This explores the importance of critical literacy in education and provides effective instruction strategies for elementary to secondary students.",
                    link: 'http://www.edugains.ca/resourcesLIT/CoreResources/Critical_Literacy_Guide.pdf',
                    filters: ['english', 'elementary', 'secondary', 'critical literacy', 'classroom management'
                    ]
                }, 
                {
                    frenchTitle: "Promouvant la littératie critique à travers le curriculucm et encourament des environnement d'apprentissage en sécurité",
                    frenchContent: "Cette ressource explore comment les enseignants peuvent mieux promouvoir littératie critique dans le programme et l'utiliser pour aider à cultiver des écoles plus sûres.",
                    title: 'Promoting Critical Literacy across the Curriculum and Fostering Safer Learning Environments',
                    content: "This resource explores how teachers can better promote critical literacy across the curriculum, and use it to help cultivate safer schools.",
                    link: 'https://thelearningexchange.ca/wp-content/uploads/2017/02/WW_PromotingCriticalLiteracy.pdf',
                    filters: ['english', 'elementary', 'secondary', 'critical literacy'
                    ]
                }, 
                {
                    frenchTitle: "Développement des compétences de la littératie critique",
                    frenchContent: "Ce PDF s'agit de développer des compétences critiques en littératie en explorant les stéréotypes masculins et féminins dans la littérature des enfants.",
                    title: 'Developing Critical Literacy Skills',
                    content: "This looks at developing critical literacy skills through the exploration of masculine and feminine stereotypes in children’s literature.",
                    link: 'https://thelearningexchange.ca/wp-content/uploads/2017/02/WW_Critical_Literacy.pdf',
                    filters: ['english', 'elementary', 'critical literacy'
                    ]
                }, 
                {
                    frenchTitle: "Les guides pour les stratégies de lecture et écriture",
                    frenchContent: "Ce site Web fournit aux éducateurs des guides de stratégie de littératie pour les étudiants de la maternelle à la 12e année. Ces guides proposent des stratégies d'enseignement pour des stratégies efficaces de littératie et d'apprentissage. Chaque guide de stratégie fournit également des liens vers divers plans de cours qui mettent en œuvre la stratégie spécifique.",
                    title: 'Reading & Writing Strategy Guides',
                    content: "This website provides educators with literacy strategy guides for grades K - 12. These guides offer teaching strategies for effective literacy teaching and learning strategies. Each strategy guide also provides links to various lesson plans that implement the specific strategy.",
                    link: 'http://www.readwritethink.org/professional-development/strategy-guides/',
                    filters: ['english', 'elementary', 'secondary', '21st century competencies', 'critical literacy'
                    ]
                }, 
                {
                    frenchTitle: "Pourquoi ensiegner la littératie critique",
                    frenchContent: "Ce PDF informe les enseignants sur ce que signifie la littératie critique dans la salle de classe et pourquoi est-ce important, en fournissant des instructions sur la façon de l'enseigner aux étudiants.",
                    title: 'Why Teach Critical Literacy?',
                    content: "This PDF informs teachers on what critical literacy means in the classroom and why is it important, providing instruction on how to teach it to students.",
                    link: 'http://www.edugains.ca/resourcesLiteracy/CE/7-12/AdolescentLiteracy/CriticalLiteracy/CriticalLiteracy_11X17.pdf',
                    filters: ['english', 'secondary', 'critical literacy'
                    ]
                }, 
            ],
            scienceList: [
                {   
                    frenchTitle: "Les stratégies efficace pour l'enseignement scientifique pour les étuidants primare et secondaire",
                    frenchContent: "Ce PDF propose une variété de stratégies efficaces pour l'enseignement des sciences de la maternelle à la 12e année. Les sujets comprennent des stratégies pour améliorer le contexte, un regroupement flexible pour promouvoir la collaboration, un interrogatoire efficace et une enquête.",
                    title: 'Effective strategies for K-12 science instruction.',
                    content: "This PDF article offers a range of effective strategies for K-12 science instruction. Topics include strategies for enhancing context, flexible grouping to promote collaboration, effective questioning, and inquiry.",
                    link: 'http://cmse.tamu.edu/documents/LittlegreenBookletv3.pdf',
                    filters: ['english', 'elementary', 'technology', 'assessment', 'secondary', 'science'
                    ]
                }, 
                {
                    frenchTitle: "Les stratégies efficace pour le science",
                    frenchContent: "Cette ressource offre des informations sur les éléments d'un enseignement scientifique de la maternelle à la 12e année. Il s'appuyant sur les résultats de la recherche. Les autres sujets comprennent la motivation des étudiants et l'engagement des connaissances antérieures des étudiants dans le domaine des sciences. Cette ressource comprend des exemples de leçons.",
                    title: 'Effective Science Instruction',
                    content: "This resource offers information on the elements of effective K-12 science instruction by drawing on research findings. Other topics include motivating students and engaging students’ prior knowledge in the subject of science. This resource includes sample lessons.",
                    link: 'https://files.eric.ed.gov/fulltext/ED521576.pdf',
                    filters: ['english', 'elementary', 'secondary', 'science'
                    ]
                }, 
                {
                    frenchTitle:"L'enseignement scientifique pour les étuidants primare",
                    frenchContent: "Cette ressource aide les enseignants à développer une meilleure compréhension de l'enseignement et de l'apprentissage en sciences primaires. Cet article examine les décisions des enseignants sur ce qu'il faut enseigner en conjonction avec la façon dont leur pratique professionnelle nourrira cet apprentissage. Les sujets abordés dans cette ressource comprennent ce qu'est la science en termes de programme d'études et le rôle des enseignants, la pensée des enseignants, l'évaluation, les aspects importants de la science primaire, les environnements d'apprentissage propices et la pertinence des sciences.",
                    title: 'Science teaching and learning in Primary Grades',
                    content: "This resource aids teachers in developing a better understanding of teaching and learning in primary science. This article discusses teachers’ decisions on what to teach in conjunction with how their professional practice will nurture this learning. Topics covered in this resource include what science is in terms of the curriculum and the role of teachers, teacher thinking, assessment, important aspects of primary science, supportive learning environments, and making science relevant.",
                    link: 'https://files.eric.ed.gov/fulltext/EJ1098113.pdf',
                    filters: ['english', 'elementary', 'assessment', 'science'
                    ]
                }, 
                {
                    frenchTitle: "Partager le biomimétisme avec les jeunes",
                    frenchContent: "Cette ressource a été crée pour aider les enseignants de la maternelle à la 12e année à établir une base générale en biomimétisme et à fournir des idées pour introduire cette nouvelle façon de penser et de résoudre les problèmes.",
                    title: 'Sharing Biomimicry with Young People',
                    content: "This resource was designed to help K-12 educators establish a general foundation in biomimicry and provide ideas for introducing this new way of thinking and problem solving.",
                    link: 'https://1d59b73swr1f1swu2v451xcx-wpengine.netdna-ssl.com/wp-content/uploads/2017/02/Sharing_Biomimicry_K12_20170201.pdf',
                    filters: ['english', 'elementary', 'secondary', 'science'
                    ]
                }, 
                {
                    frenchTitle: "Se concentrer sur l'apprentissage inquisitif",
                    frenchContent: 'Ce PDF fournit aux éducateurs une excellente base sur enquête. Il est idéal pour les sciences et les mathématiques.',
                    title: 'Focus on Inquiry',
                    content: "This PDF provides educators with a great basis on inquiry. It is great for both science and mathematics.",
                    link: 'http://inquiry.galileo.org/files/focus-on-inquiry-pdf.pdf',
                    filters: ['english', 'elementary', 'secondary', '21st century competencies', 'mathematics', 'science'
                    ]
                },
                {
                    frenchTitle: "Reflection en design",
                    frenchContent: "Le blog comprend une vidéo pour aider les enseignants à réfléchir sur la réflexion en design et à la façon dont ils peuvent l'utiliser dans leur classe.",
                    title: 'Design Thinking',
                    content: "The blog includes a video to help teachers beeter think about design thinking and how they can use it in their classroom.",
                    link: 'https://galileo.org/teachersasdesigners/',
                    filters: ['english', 'elementary', 'secondary', '21st century competencies', 'science',
                    ]
                },
                {
                    frenchTitle: "Les clés exercices d'apprentisage pour l'éducation écologique, la citoyenneté, et la curabilité",
                    frenchContent: "Cette ressource propose des stratégies clés qui transforment l'apprentissage pour l'éducation environnementale, la citoyenneté et la durabilité. Il a été développé pour aider les éducateurs à transformer les expériences d'apprentissage et répondre aux besoins des apprenants du 21e siècle.",
                    title: 'Key Learning Strategies for Environmental Education, Citizenship, and Sustainability',
                    content: "This resource offers Key Strategies that Transform Learning for Environmental Education, Citizenship and Sustainability. It was developed to assist educators in transforming learning experiences to meet the needs of 21st Century learners.",
                    link: 'http://www.lsf-lst.ca/media/LSF_Connecting_the_DOTS_ExecutiveSummary.pdf',
                    filters: ['english', 'elementary', 'secondary', 'science', '21st century competencies'
                    ]
                },  
            ],
            technologyList: [
                {
                    frenchTitle: "Aide pédagogique",
                    frenchContent: "Cette chaîne de YouTube propose une variété de contenus pédagogiques pour les enseignants.",
                    title: 'Pedagogy Help',
                    content: "This YouTube channel provides a variety of pedagogical content for teachers to learn from. ",
                    link: 'https://www.youtube.com/channel/UCow_-ADysDA1oqYmov07H9A/videos',
                    filters: ['french', 'elementary', 'secondary', 'special education','assessment', 'critical literacy', '21st century competencies', 'science', 'mathematics', 'classroom management', 'technology', 'early literacy'
                    ]
                },
                {
                    frenchTitle:"Ted Talk",
                    frenchContent: "Ted Talks pour l'inspiration, à utiliser avec les étudiants ou pour apprendre. Cette ressource fournit aux enseignants tout ce dont ils peuvent avoir besoin au format vidéo.",
                    title: 'Ted Talks',
                    content: "Ted Talks for inspiration, to use with students, or to learn from. This resource supplies teachers with anything they may need in video format. ",
                    link: 'https://www.ted.com/talks?topics%5B%5D=education&language=en&sort=newest',
                    filters: ['french', 'elementary', 'secondary', 'special education','assessment', 'critical literacy', '21st century competencies', 'science', 'mathematics', 'classroom management', 'technology', 'early literacy'
                    ]
                }, 
                {
                    frenchTitle: "La culture numérique",
                    frenchContent: "Ce site Web propose un programme de formation en culture numérique. Il comprend des didacticiels autogérés, des PDF téléchargeables et un guide de l'enseignant pour aider avec la mise en œuvre de la culture numérique en classe.",
                    title: 'Digital Literacy',
                    content: "This website provides a digital literacy training program. It features self directed tutorials, downloadable PDF's and a teachers guide to assist in the implementation of digital literacy in the classroom.",
                    link: 'https://mediasmarts.ca/teacher-resources/digital-literacy-101',
                    filters: ['english', 'elementary', 'secondary', 'technology']
                },  
            ],
            mathematicsList: [
                {
                    frenchTitle: "Les guides pour l'enseignement efficace des maths",
                    frenchContent: "Ceci est la page Web principale qui contient plus de 24 PDF concernant l'enseignement des mathématiques. Il y a aussi d'autres liens directs vers les meilleurs documents ici sur la page ELI comme les PDF de « Les fondations pour l'enseignement efficace des mathématiques » et « Résolution de problèmes et la communication ». Cliquer sur 'Liste des modules' pour voir tous les PDF.",
                    title: 'Guides to Effective Math Instruction',
                    content: "This is the main webpage that has over 24 PDF regarding math instruction. There are also other direct links to the best documents here on the ELI page like the Foundations to Effective Math instruction and Problem Solving and Communication pdfs.",
                    link: 'http://www.edugains.ca/newsite/math/guides_effective_instruction.html',
                    filters: ['english', 'french', 'elementary', 'secondary', 'mathematics']
                }, 
                {
                    frenchTitle: "Les vidéos sur les leaders en mathématiques",
                    frenchContent: "Les vidéos suivantes offrent aux éducateurs une grande variété de pédagogie mathématique par les leaders de la communauté mathématique.",
                    title: 'Videos on leaders in mathematics',
                    content: "The following videos provide educators with a large range of mathematical pedagogy from leaders in the mathematical community. ",
                    link: 'http://thelearningexchange.ca/projects/leaders-in-educational-thought-special-edition-on-mathematics/?pcat=999&sess=1',
                    filters: ['english', 'elementary', 'secondary', 'mathematics']
                },  
                {
                    frenchTitle: "Apprendre enseigner innover: les mathématiques",
                    frenchContent: "Le page Apprendre enseigner innover est un excellent site pour le développement professionnel, voici un lien vers leur site spécifique aux mathématiques. Nous avons également inclus des liens spécifiques vers d'excellentes ressources sur notre site.",
                    title: 'The Learning Exchange: Mathematics',
                    content: "The learning exchange is a great site for all things professional developement, here is a link to their math specific site. We have also included specific links to great resources within the site.",
                    link: 'https://math.thelearningexchange.ca/',
                    filters: ['english', 'french', 'elementary', 'secondary', 'mathematics']
                }, 
                {
                    frenchTitle: "Les éléments fondamentaux en mathématiques",
                    frenchContent: "Cette page Web présente une série de vidéos d'apprentissage professionnel des mathématiques avec des ressources pédagogiques élaborées par le ministère.",
                    title: 'Mathematics Professonal Learning Video Series',
                    content: "This web page features a mathematics professional learning video series with ministry developed educator resources.",
                    link: 'https://math.thelearningexchange.ca/educator-resources/#section7',
                    filters: ['english', 'french', 'elementary', 'secondary', 'mathematics']
                }, 
                {
                    frenchTitle: "Les éléments fondamentaux en mathématiques formation",
                    frenchContent: "Ce module de formation comprend un guide des enseignants et des vidéos se concentrant sur les éléments fondamentaux des mathématiques. ",
                    title: 'Fundmentals of Math: Learning Module',
                    content: "This training module features a PDF Teachers guide and videos focusing on the fundamentals of math related to the expectations in the Number Sense & Numeration strand, and expectations that relate to number properties in the Patterning and Algebra strand. from Gr.1 -8.",
                    link: 'https://math.thelearningexchange.ca/training/fundamentals-of-math/',
                    filters: ['english', 'french', 'elementary', 'secondary', 'mathematics']
                },  
                {
                    frenchTitle: "Les ressources pour la fermeture du écart ",
                    frenchContent: "Ces ressources mathématiques ont été élaborées pour aider les enseignants à fournir des mesures correctives pour les étudiants qui sont en retard en mathématiques. Il existe un diagnostic et un ensemble de matériel d'intervention pour de nombreux domaines mathématiques, du niveau intermédiaire au niveau supérieur.",
                    title: 'Math: Gap Closing Teacher Resources',
                    content: "These math resources were developed to help teachers provide precisely targeted remediation for students who are behind in mathematics. There is a diagnostic and a set of intervention materials for numerous math strands from junior to senior grades.",
                    link: 'https://math.thelearningexchange.ca/educator-resources/gap-closing/',
                    filters: ['english', 'french', 'elementary', 'secondary', 'mathematics', 'special education']
                }, 
                {
                    frenchTitle: "Les fondations pour l'enseignement efficace des mathématiques",
                    frenchContent: "Ce PDF est une excellente ressource pour améliorer les croyances et la compréhension de l'enseignement des mathématiques.",
                    title: 'Foundations to Effective Math Instruction',
                    content: "This PDF is a great resource for improving beliefs and understanding of math instruction.",
                    link: 'https://oame.on.ca/eduproject/ontariomathedresources/files/Guide%20to%20Effective%20Instruction%20in%20Mathematics%20Vol%201%20K-6.pdf',
                    filters: ['english', 'french', 'elementary', 'mathematics']
                },  
                {
                    frenchTitle: "Résolution de problèmes et la communication",
                    frenchContent: "Ce guide aide les enseignants à apprendre plus sur la résolution de problèmes dans une salle de classe de mathématiques. Il plonge également dans la communication de la pensée mathématique.",
                    title: 'Problem solving and communication',
                    content: "This guide helps educators learn more about problem-solving in a mathematics classroom. It also dives into communicating the math thinking.",
                    link: 'https://oame.on.ca/eduproject/ontariomathedresources/files/Guide%20to%20Effective%20Instruction%20in%20Mathematics%20Vol%202%20K-6.pdf',
                    filters: ['english', 'french', 'elementary', 'mathematics']
                },  
                {
                    frenchTitle: "L'enseignement des compétences mathématiques",
                    frenchContent: "Ce PDF est une ressource utile pour aider les enseignants à enseigner à leurs étudiants comment utiliser les compétences en processus mathématiques. Il explique pourquoi il est important d'impliquer les étudiants dans les processus mathématiques, en proposant une liste de stratégies pédagogiques pour chaque attente du processus mathématique, y compris des exemples de questions à poser aux étudiants et des exemples de commentaires à offrir aux étudiants.",
                    title: 'Teaching Mathematical Process Skills',
                    content: "This PDF is a useful resource for helping teachers to teach their students how to use mathematical process skills. It discusses why it is important to engage students in mathematical processes, offering a list of instructional strategies for each mathematical process expectation, including sample questions to ask students and sample feedback to offer students.",
                    link: 'http://www.edugains.ca/resources/LearningMaterials/MathProcesses/MathProcessessPackage.pdf',
                    filters: ['english', 'elementary', 'mathematics']
                }, 
                {
                    frenchTitle: "Un salle de classe efficace en mathématiques",
                    frenchContent: "Cette ressource aide les enseignants à comprendre l'importance de l'observation dans le cours de mathématiques, en décrivant le rôle de l'enseignant et des étudiants, ainsi qu'en offrant certaines des meilleures pratiques pour un cours de mathématiques efficace, tout en notant certaines pratiques inefficaces.",
                    title: 'An Effective Mathematics Classroom',
                    content: "This resource helps teachers understand the importance of observation in the mathematics classroom, outlining the role of both teacher and students, as well as offering some of the best practices for an effective math classroom, while noting some ineffective practices.",
                    link: 'https://www.andrews.edu/sed/leadership_dept/webinars/presentationdocuments/the_effective_mathematics_classrroom.pdf',
                    filters: ['english', 'elementary','secondary', 'mathematics']
                },  
                {
                    frenchTitle: "L'apprentissage inquisitif",
                    frenchContent: "L'article suivant explique comment les enseignants peuvent mettre en œuvre l'apprentissage basé sur l'enquête dans leur classe, il comprend les différents types d'apprentissage basé sur l'enquête et quelques exemples.",
                    title: 'Inquiry Based Learning',
                    content: "The following article shares how teachers can implement inquiry based learning into their classroom, it includes the different types of inquiry based learning and some examples.",
                    link: 'https://academicpartnerships.uta.edu/articles/education/inquiry-based-learning-math-classroom.aspx',
                    filters: ['english', 'elementary','secondary', 'mathematics']
                }, 
                {
                    frenchTitle: "Comment utiliser les matérieux virtuel en mathématiques",
                    frenchContent: "Cette vidéo est sur l'utilisation de manipulateurs mathématiques lors de l'apprentissage en ligne. Il est destiné à la maternelle à la 2e année",
                    title: 'Using Virtual Manipulatives in Mathematics',
                    content: "This video is all about using math manipulatives during online learning. It is geared towards kindergarten to grade 2",
                    link: 'https://nctm.wistia.com/medias/w6v28zyq8e',
                    filters: ['english', 'elementary','secondary', 'mathematics', '21st century competencies']
                },  
                {
                    frenchTitle: "Les solutions en mathématiques",
                    frenchContent: "Ce site Web offre aux enseignants des ressources gratuites pour soutenir leur développement professionnel en matière de mathématiques. Les enseignants peuvent accéder aux ressources sous diverses formes, notamment des articles de blog, des articles, des séminaires en ligne et des vidéos.",
                    title: 'Math Solutions',
                    content: "This website provides teachers with free resources to support their professional development with regards to mathematics. Educators can access resources in a variety of forms including blog posts, articles, webinars and videos.",
                    link: 'https://mathsolutions.com/free-resources/',
                    filters: ['english', 'elementary', 'mathematics']
                },
                {
                    frenchTitle: "Les trois-actes tâches",
                    frenchContent: "Ce site Web offre aux éducateurs une introduction aux trois-actes tâches en mathématiques avec un podcast, une liste de ressources pour l'enseignement et un moteur de recherche pour trouver trois-actes tâches en mathématiques.",
                    title: 'Three Act Math Tasks',
                    content: "This website provides educators with an introduction to three act math tasks with a podcast, a list of resources for teaching and a search engine to find three act math tasks.",
                    link: 'https://blog.mrmeyer.com/2011/the-three-acts-of-a-mathematical-story/',
                    filters: ['english', 'elementary', 'secondary', 'mathematics']
                },
                {
                    frenchTitle: "Sept éléments foundational pour le progression en mathématiques",
                    frenchContent: "Cette ressource offre aux éducateurs un aperçu de sept éléments qui peuvent être utilisés pour améliorer la réussite et la compréhension des étudiants en mathématiques.",
                    title: 'Seven Foundational Principles for Improvement in Mathematics K-12',
                    content: "This resource provides educators with an overview of seven priciples than can be used to improve student success and understanding in mathematics.",
                    link: 'http://www.edu.gov.on.ca/eng/teachers/studentsuccess/FoundationPrincipals.pdf',
                    filters: ['english', 'elementary', 'secondary', 'mathematics']
                },
            ],
            activeFilters: [],
            filterGridState: false,
            languageState: 'eng',
        }
    }

    toggleGridState = () => {
        if (this.state.filterGridState === false) {
            this.setState({filterGridState: true})
        } else {
            this.setState({filterGridState: false})
        }
    }

    toggleLanguageState = () => {
        if (this.state.languageState === 'eng') {
            this.setState({languageState: 'fr'})
        } else {
            this.setState({languageState: 'eng'})
        }
    }

    addOrRemoveFilter = (e) => {
        const newActiveFilters = this.state.activeFilters
        if (newActiveFilters.includes(e.target.id)) {
            const listWithRemovedItem = newActiveFilters.filter(item => {
                return item !== e.target.id
            })
            this.setState({activeFilters: listWithRemovedItem})
        } else {
            newActiveFilters.push(e.target.id)
            this.setState({activeFilters: newActiveFilters})
        }
    }

    render() {
        let filteredAssessmentsList = []
        let filteredClassroomManagementList = []
        let filteredSpecialEducationList = []
        let filteredCenturyCompetenciesList = []
        let filteredEarlyLiteracyList = []
        let filteredSchoolLeadershipList = []
        let filteredCriticalLiteracyList = []
        let filteredScienceList = []
        let filteredTechnologyList = []
        let filteredMathematicsList = []

        this.state.assessmentsList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredAssessmentsList.includes(item)) {
                filteredAssessmentsList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredAssessmentsList = this.state.assessmentsList
        }


        this.state.classroomManagementList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredClassroomManagementList.includes(item)) {
                filteredClassroomManagementList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredClassroomManagementList = this.state.classroomManagementList
        }

        this.state.specialEducationList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredSpecialEducationList.includes(item)) {
                filteredSpecialEducationList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredSpecialEducationList = this.state.specialEducationList
        }

        this.state.centuryCompetenciesList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredCenturyCompetenciesList.includes(item)) {
                filteredCenturyCompetenciesList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredCenturyCompetenciesList = this.state.centuryCompetenciesList
        }

        this.state.earlyLiteracyList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredEarlyLiteracyList.includes(item)) {
                filteredEarlyLiteracyList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredEarlyLiteracyList = this.state.earlyLiteracyList
        }

        this.state.schoolLeadershipList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredSchoolLeadershipList.includes(item)) {
                filteredSchoolLeadershipList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredSchoolLeadershipList = this.state.schoolLeadershipList
        }

        this.state.criticalLiteracyList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredCriticalLiteracyList.includes(item)) {
                filteredCriticalLiteracyList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredCriticalLiteracyList = this.state.criticalLiteracyList
        }

        this.state.scienceList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredScienceList.includes(item)) {
                filteredScienceList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredScienceList = this.state.scienceList
        }

        this.state.technologyList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredTechnologyList.includes(item)) {
                filteredTechnologyList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredTechnologyList = this.state.technologyList
        }

        this.state.mathematicsList.forEach(item => {
            let counter = 0
            item.filters.forEach(filter => {
                if (this.state.activeFilters.includes(filter)) {
                    counter++
                } 
            })
            if (counter === this.state.activeFilters.length && !filteredMathematicsList.includes(item)) {
                filteredMathematicsList.push(item)
            }
        })
        if (this.state.activeFilters.length === 0) {
            filteredMathematicsList = this.state.mathematicsList
        }

        return (
            <Layout>
            <div className='eli-page'>
                <div className='eli-page-header-image'>
                    <div className='eli-page-dot-bg'>
                    </div>
                    <div className='eli-page-header-text-box'>
                        ONLINE RESOURCES
                    </div>
                </div>
                <div className='eli-page-subheader-text'>
                    {
                        this.state.languageState === 'eng' ?
                        'Below you will find a number of resources and supports that can be accessed by educators globally to support their professional learning endeavours. There is access to portable document formats, websites and videos. You may utilize the filters above to narrow down your search, and pay close attention to the *language each resource is available in. This will be noted in the resource preview.'
                        :
                        "Vous trouverez ci-dessous un certain nombre de ressources et de soutiens que les éducateurs de n’importe d'où peuvent utiliser pour soutenir leurs efforts d'apprentissage professionnel. Il y a accès à des formats de document portables, des sites Web et des vidéos. Vous pouvez utiliser les filtres ci-dessus pour affiner votre recherche et porter une attention particulière à la langue dans laquelle chaque ressource est disponible. Cela sera indiqué dans l'aperçu de la ressource."
                    } 
                </div>
                <div className='eli-page-eng-french-container'>
                    <div 
                    style={
                        this.state.languageState === 'eng' ?
                        {backgroundColor: 'black', color: "white"} :
                        null
                    }
                    onClick={this.toggleLanguageState} 
                    className='eli-page-eng-french-tab'>ENG</div>
                    <div 
                    style={
                        this.state.languageState === 'fr' ?
                        {backgroundColor: 'black', color: "white"} :
                        null
                    }
                    onClick={this.toggleLanguageState} 
                    className='eli-page-eng-french-tab'>FR</div>
                </div>
                <div className='eli-page-filters-header'>
                    <div 
                    onClick={this.toggleGridState} 
                    style={
                        this.state.filterGridState ?
                        {transform: 'rotate(360deg)'} :
                        null
                    }
                    className='eli-page-filters-header-arrow'></div>
                    <div onClick={this.toggleGridState}>Filters</div>
                </div>
                <Fade when={this.state.filterGridState} left>
                <div 
                style={
                    this.state.filterGridState ?
                    {display: 'grid'} :
                    {display: 'none'}
                }
                className='eli-page-filters-subheader'>
                    Select All That Apply
                </div>
                <div 
                style={
                    this.state.filterGridState ?
                    {display: 'grid'} :
                    {display: 'none'}
                }
                className='filter-grid'>
                    <div 
                    style={
                        this.state.activeFilters.includes('english') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='english'>{this.state.languageState === 'eng' ? 'English' : 'Anglais'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('french') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='french'>{this.state.languageState === 'eng' ? 'French' : 'Francais'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('elementary') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='elementary'>{this.state.languageState === 'eng' ? 'Elementary' : 'Primaire'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('secondary') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='secondary'>{this.state.languageState === 'eng' ? 'Secondary' : 'Secondaire'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('special education') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='special education'>{this.state.languageState === 'eng' ? 'Special Education' : 'Enfance en Difficulte'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('early literacy') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='early literacy'>{this.state.languageState === 'eng' ? 'Early Literacy' : 'Petite Enfance'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('critical literacy') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='critical literacy'>{this.state.languageState === 'eng' ? 'Critical Literacy' : 'Litteratie Critique'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('school leadership') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='school leadership'>{this.state.languageState === 'eng' ? 'School Leadership' : 'Leadership Scolaire'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('science') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='science'>Science</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('mathematics') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='mathematics'>{this.state.languageState === 'eng' ? 'Mathematics' : 'Mathematiques'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('assessment') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='assessment'>{this.state.languageState === 'eng' ? 'Assessment' : 'Evaluation'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('classroom management') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='classroom management'>{this.state.languageState === 'eng' ? 'Classroom Management' : 'Gestion de Classe'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('technology') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='technology'>{this.state.languageState === 'eng' ? 'Technology' : 'Technologie'}</div>
                    <div 
                    style={
                        this.state.activeFilters.includes('21st century competencies') ? {color: 'white', backgroundColor: 'black'} : null
                    }
                    onClick={this.addOrRemoveFilter} className='filter-button' id='21st century competencies'>{this.state.languageState === 'eng' ? '21st Century Competencies' : 'Les Competences du 21eme siecle'}</div>
                </div>
                </Fade>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'Assessment' : 'Evaluation'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredAssessmentsList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredClassroomManagementList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'Classroom Management' : 'Gestion de Classe'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredClassroomManagementList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredSpecialEducationList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'Special Education' : 'Enfance en difficulte'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredSpecialEducationList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredCenturyCompetenciesList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? '21st Century Competencies' : 'Les Competences du 21eme siecle'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredCenturyCompetenciesList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredEarlyLiteracyList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'Early Literacy' : 'Petite Enfance'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredEarlyLiteracyList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredSchoolLeadershipList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'School Leadership' : 'Leadership Scolaire'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredSchoolLeadershipList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredCriticalLiteracyList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'Critical Literacy' : 'litteratie critique'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredCriticalLiteracyList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredScienceList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    Science
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredScienceList.map(item => {
                            return (
                                <div className='grid-item'>
                                   <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredTechnologyList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'Technology' : 'Technologie'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredTechnologyList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div 
                style={
                    !filteredMathematicsList.length ?
                    {display: 'none'} :
                    null
                }
                className='grid-header'>
                    {this.state.languageState === 'eng' ? 'Mathematics' : 'Mathematiques'}
                </div>
                <div 
                style={
                    !filteredAssessmentsList.length ?
                    {marginBottom: '0'} :
                    {marginBottom: '4em'}
                }
                className='grid-container'>
                    {
                        filteredMathematicsList.map(item => {
                            return (
                                <div className='grid-item'>
                                    <div className='grid-item-header'>
                                        {this.state.languageState === 'eng' ? item.title : item.frenchTitle}
                                    </div>
                                    <div className='grid-item-language-tag'>
                                        {
                                            item.filters.includes('english') ?
                                                item.filters.includes('french') ? 
                                                'Available in English and French' :
                                                'Available in English' :
                                            'Available in French'
                                        }
                                    </div>
                                    <div className='grid-item-content'>
                                        {this.state.languageState === 'eng' ? item.content : item.frenchContent}
                                    </div>
                                    <div className='grid-item-button-div'>
                                        <a className='grid-item-button' href={item.link}>
                                            See Resource
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            </Layout>
        )
    }
}

export default EliPage